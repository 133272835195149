@use "variables";

/* NAVIGATION */

.navHeader {
  background-color: variables.$dark;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;

  display: flex;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0); /* Initial transparent background */
  backdrop-filter: blur(0px); /* Initial blur amount */
  -webkit-backdrop-filter: blur(0px); /* For Safari and older Chrome */

  transition: background-color 0.5s ease, backdrop-filter 0.5s ease; /* Transition effect for smooth change */
}

.navTagClass {
  flex-basis: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuListContainer {
  display: none;
}

.navHeader.scrolled {
  background-color: rgba(variables.$dark, 0.5);
  backdrop-filter: blur(16px); /* Blur amount when scrolled */
  -webkit-backdrop-filter: blur(16px); /* For Safari and older Chrome */

  transform: translateY(0px);
  background-color: rgba(10, 22, 47, 0.5);
  box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.7);
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  display: flex;

  li {
    margin-right: 30px;
  }
  li:last-child {
    margin-right: 0px;
  }
}

.navListItemActive {
  color: variables.$accent !important ; /* Change to the desired highlight color */
  /* Add any additional styling you want for the active link */
}

.navListItemActive::before {
  content: "";
  // font-size: 12px;
  display: inline-flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  background-image: url("../assets/svg/selectedArrow.svg");
  width: 10px; /* Set the width of the pseudo-element */
  height: 10px; /* Set the height of the pseudo-element */
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 5px;
}

.navList li a {
  text-decoration: none;
  color: variables.$light001;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 1px;
  padding-bottom: 10px;
}

.logoContainer {
  margin-left: -10px;
  // margin-left: 10px;
}

.menuAsideContainer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 50px 10px;
  width: min(75vw, 400px);
  height: 100vh;
  outline: 0px;
  background-color: variables.$dark002;
  box-shadow: -10px 0px 30px -15px variables.$dark;
  z-index: 9;
  transform: translateX(100vw);
  visibility: hidden;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  nav {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    color: variables.$light;
    text-align: center;
    // background-color: rgba(137, 43, 226, 0.195);

    ul {
      display: flex;
      flex-direction: column;
      gap: 40px;
      li {
        // background-color: rgba(43, 147, 226, 0.195);
        // margin-bottom: 20px;
      }
      a {
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        position: relative;
        // transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        padding: 3px 20px 20px;
      }
    }
  }
}

.hamburgerButton {
  // display: flex;
  // -webkit-box-pack: center;
  // justify-content: center;
  // -webkit-box-align: center;
  // align-items: center;
  position: relative;
  z-index: 10;
  // margin-right: -15px;
  // padding: 15px;
  border: 0px;
  cursor: pointer;
  background-color: transparent;
  // background-color: rgba($color: variables.$dark002, $alpha: 0.2);
  padding: 10px;
  border-radius: 5px;

  // color: inherit;
  // text-transform: none;
  // transition-timing-function: linear;
  // transition-duration: 0.15s;
  // transition-property: opacity, filter;
}
.hamburgerBtnContainer {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
}

.strokeClass {
  border-radius: 1px;
  right: 0px;
  height: 2px;
  position: absolute;
  background-color: variables.$accent;
  transition: all 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  // transform: rotate(0deg);
}
.innerStroke1 {
  top: 0%;
  width: 20px;
}
.innerStroke2 {
  top: 50%;
  width: 30px;
}
.innerStroke3 {
  top: 100%;
  width: 20px;
}
.innerStroke::after {
  width: 120%;
}

.innerStroke1Update {
  top: 50%;
  transform: rotate(45deg);
  width: 30px;
}

.innerStroke2Update {
  opacity: 0;
}
.innerStroke3Update {
  top: 50%;
  width: 30px;
  transform: rotate(-45deg);
}
@media (max-width: variables.$tabWidth) {
  .navListContainer {
    display: none;
  }
  .menuListContainer {
    display: block;
  }
  .navHeader {
    padding: 10px 30px;
  }
  .logoContainer {
    #logoContainerSvgId {
      width: 100px;
      height: 50px;
    }
  }
}

.menuShow {
  visibility: visible;
  transform: translateX(0vw);
}

.filterBlur {
  filter: blur(10px);
}
@media (max-width: variables.$mobileWidth) {
  .navHeader {
    padding: 10px 20px;
  }
  .logoContainer {
    margin-left: 0px;
  }
}

@media (min-width: variables.$mobileWidth) {
  .navHeader {
    padding: 10px 50px;
  }
}
